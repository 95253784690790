/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #5d5d5d;
  border-radius: 6px;
  text-decoration: none;
  padding: 0.75rem 0;
  min-width: 130px;
  & :hover {
    border: 1px solid #ffffff;
    transition: 0.3s;
  }
  @media (max-width: 424px) {
    min-width: 235px;
  }
`

const primaryCSS = css`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  padding: calc(0.75rem + 1px) 0;
  border: 0;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
    border: 0;
  }
`

type Props = {
  href: string
  text: string
  styles?: any
  target?: string
  primary?: boolean
  className?: string
  onClick?: any
}

const CustomAnchor = ({
  href,
  text,
  styles = null,
  target,
  primary = false,
  className = "",
  onClick,
}: Props) => {
  const primaryStyles = primary ? primaryCSS : null

  return (
    <Anchor
      href={href}
      css={[primaryStyles, styles]}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      className={className}
      onClick={onClick}
    >
      {text}
    </Anchor>
  )
}

export default CustomAnchor
