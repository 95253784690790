import React from "react"

import Navbar from "./navbar"

const Header = () => (
  <>
    <Navbar />
  </>
)

export default Header
