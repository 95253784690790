import React from "react"
import styled from "@emotion/styled"

import Disclaimer from "./disclaimer"

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d1010;
  padding: 7rem 0 3rem;
  @media (max-width: 424px) {
    padding: 2.5rem 0 3rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 2.5rem 0 3rem;
  }
`

const FooterSection = () => (
  <Footer>
    <Disclaimer />
  </Footer>
)

export default FooterSection
