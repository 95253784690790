import React from "react"
import styled from "@emotion/styled"

const CapitariaLogo = styled.img`
  width: 300px;
  @media (max-width: 424px) {
    width: 300px;
  }
`

const CapitariaLogoWhite = () => (
  <CapitariaLogo src={`/kt-spirit.png`} />
)

export default CapitariaLogoWhite
