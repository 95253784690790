import React from "react"
import styled from "@emotion/styled"

import Footer from "./footer"
import Header from "./header"
import "./layout.css"

const Container = styled.main`
  display: flex;
  flex-direction: column;
  background-color: #212526;
  flex-grow: 1;
`

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

export default Layout
