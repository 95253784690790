import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import CapitariaLogoWhite from "./images/capitaria-logo-white"

import CustomAnchor from "./custom-anchor"

const DesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 1rem 2rem;
  @media (max-width: 424px) {
    display: none;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 1.25rem 2rem;
  }
`

const MobileContainer = styled.div`
  background-color: #000000;
  width: 100%;
  display: none;
  @media (max-width: 424px) {
    display: block;
  }
`

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
  text-decoration: none;
  padding-right: 0.25rem;
  min-height: 22px;
`

const LinkList = styled.ul`
  list-style: none;
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  margin: 0;
  @media (max-width: 424px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    padding-top: 2rem;
    border-top: 1px solid #000000;
  }
`

const Item = styled.li`
  text-align: center;
`

const anchorCSS = {
  marginLeft: "0.75rem",
  "@media (max-width: 424px)": {
    marginLeft: 0,
    marginBottom: "1rem",
  },
}

type LinkType = {
  text: string
  path: string
  className: string
  primary?: boolean
  onClick?: () => void
}

const links: LinkType[] = []

const NavbarItem = ({ text, path, primary, className, onClick }: LinkType) => (
  <Item>
    <CustomAnchor
      className={className}
      styles={anchorCSS}
      href={path}
      target="_blank"
      text={text}
      primary={primary}
      onClick={onClick}
    />
  </Item>
)

const DesktopNavbar = () => {

  return (
    <DesktopContainer>
      <Link to={"/"}>
        <CapitariaLogoWhite />
      </Link>

      <LinkList>
        {links.map((linkProps, idx) => (
          <NavbarItem
            key={`nav-link-${idx}`}
            {...linkProps}
            onClick={() => linkProps.onClick && linkProps.onClick()}
          />
        ))}
      </LinkList>
    </DesktopContainer>
  )
}

const MobileNavbar = () => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <MobileContainer>
      <MobileHeader>
        <Link to={"/"}>
          <CapitariaLogoWhite />
        </Link>

        <ToggleButton
          className={isOpened ? "hamburger active" : "hamburger"}
          onClick={() => setIsOpened(!isOpened)}
        >
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </ToggleButton>
      </MobileHeader>

      {isOpened && (
        <LinkList>
          {links.map((linkProps, idx) => (
            <NavbarItem
              key={`nav-link-${idx}`}
              {...linkProps}
              onClick={() => linkProps.onClick && linkProps.onClick()}
            />
          ))}
        </LinkList>
      )}
    </MobileContainer>
  )
}

const Navbar = () => (
  <>
    <DesktopNavbar />
    <MobileNavbar />
  </>
)

export default Navbar
